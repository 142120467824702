<template>
	<div class="px-6 overflow-auto">
		<div class="mb-6">
			<label class="text-black">Start og sluttdato</label>
			{{campaign.startAt | moment('ll')}} til {{campaign.endAt | moment('ll')}}
		</div>
		
		<div v-if="campaign.description">
			<label class="text-black">Beskrivelse av kampanjen</label>
			<p>
				{{campaign.description}}
			</p>
		</div>
		
		<div class="mb-6">
			<label class="text-black">Måloppnåelse i kampanjeperioden</label>
			
			<template v-if="campaign.type == 'products'">
				
				<div v-if="campaign.campaignTargets.products.metric == 'total'">
					Mål er kampanjens totale salg.
					
					<div v-if="campaign.campaignTargets.products.sum">
						Totalsum: {{ campaign.campaignTargets.products.sum }} kr.
					</div>
					<div v-else-if="campaign.campaignTargets.products.qty">
						Totalt antall: {{ campaign.campaignTargets.products.sum }} stykk.
					</div>
					<div v-else>
						<strong>Mangler totalverdi.</strong>
					</div>
				</div>
				<div v-else-if="campaign.campaignTargets.products.metric == 'individually'">
					Mål individuelt for produkter solgt.
				</div>
				
				<div v-if="campaign.campaignTargets.products.mode == 'all'">
					Alle avdelingsprodukter er med i kampanjen.
				</div>
				<div v-else-if="campaign.campaignTargets.tasks.mode == 'some'">
					Disse produktene er med i kampanjen:
				</div>
				
				<table class="bg-beige mt-6">
					<thead>
						<tr>
							<th>Produkter</th>
							<th v-if="campaign.campaignTargets.products.metric == 'individually'">Mål</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(product,index) in department.products"
							:key="'productId_'+product.productId+'_'+index">
							
							<td>{{ product.name }}</td>
							<td  
								v-if="campaign.campaignTargets.products.metric == 'individually'" 
								class="text-right">
								
								{{ campaign.products.find( p => p.productId == product.productId) && campaign.products.find( p => p.productId == product.productId).target ? campaign.products.find( p => p.productId == product.productId).target.sum+' kr' : '-' }}
							</td>
						</tr>
					</tbody>
				</table>
				
			</template>
			<template v-else-if="campaign.type == 'tasks'">
				
				<div v-if="campaign.campaignTargets.tasks.metric == 'total'">
					Mål er gjennomførte oppgaver.
					
					<div v-if="campaign.campaignTargets.tasks.qty">
						Totalt antall: {{ campaign.campaignTargets.tasks.qty }} oppgaver.
					</div>
					<div v-else>
						<strong>Mangler totalverdi.</strong>
					</div>
				</div>
				<div v-else-if="campaign.campaignTargets.tasks.metric == 'individually'">
					Mål er individuelt for oppgaver gjennomført.
				</div>
				
				<div v-if="campaign.campaignTargets.tasks.mode == 'all'">
					Alle avdelingsoppgaver er med i kampanjen.
				</div>
				<div v-else-if="campaign.campaignTargets.tasks.mode == 'some'">
					Disse avdelingsoppgavene er med i kampanjen:
				</div>
				
				<table class="bg-beige mt-6">
					<thead>
						<tr>
							<th>Oppgaver</th>
							<th v-if="campaign.campaignTargets.tasks.metric == 'individually'">Mål</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(task,index) in department.tasks"
							:key="'taskId_'+task.taskId+'_'+index">
							
							<td>{{ task.name }}</td>
							<td 
								v-if="campaign.campaignTargets.tasks.metric == 'individually'" 
								class="text-right">
								
								{{ campaign.tasks.find( t => t.taskId == task.taskId) && campaign.tasks.find( t => t.taskId == task.taskId).target ? campaign.tasks.find( t => t.taskId == task.taskId).target.qty : '-' }}
							</td>
						</tr>
					</tbody>
				</table>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		campaign: {
			type: Object,
			required: true,
		},
	},
	
	computed:{
		departments(){
			return this.$store.getters.getDepartments;
		},
		
		department(){
			return this.departments.find( d => d.departmentId == this.campaign.departmentId );
		},
	},
	
	data() {
		return {
			loading: null,
		}
	},
}
</script>